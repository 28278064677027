import React, { useState } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

interface Task {
  id: string;
  properties: {
    Name: { title: [{ plain_text: string }] };
    Date: { date: { start: string } };
    Person: { rich_text: [{ plain_text: string }] };
    'Success Rate': { rich_text: [{ plain_text: string }] };
  };
}

const fetchNotionData = async () => {
  const response = await axios.get('/api/notion-data');
  return response.data.results;
};

const Admin: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [password, setPassword] = useState('');
  const { data, isLoading, error } = useQuery<Task[], Error>('notionData', fetchNotionData);

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    if (password === '1234') {
      setIsLoggedIn(true);
    } else {
      alert('잘못된 비밀번호입니다.');
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="login-form" style={{ maxWidth: '300px', margin: '100px auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px' }}>
        <h2>Admin Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="비밀번호를 입력하세요"
            style={{ width: '91.5%', padding: '10px', marginBottom: '10px' }}
          />
          <button type="submit" style={{ width: '100%', padding: '10px', background: '#007bff', color: 'white', border: 'none', borderRadius: '5px' }}>
            로그인
          </button>
        </form>
      </div>
    );
  }

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!Array.isArray(data)) return <div>No data available</div>;

  // 현재 주의 일요일 시작
  const currentWeekStart = dayjs().startOf('week'); // 일요일 시작
  const twoWeeksAgoStart = currentWeekStart.subtract(2, 'week'); // 2주 전 일요일 시작

  const twoWeekData = data.filter(task => {
    const taskDate = dayjs(task.properties.Date.date.start);
    return taskDate.isAfter(twoWeeksAgoStart) && taskDate.isBefore(currentWeekStart.endOf('week')); // 일요일 ~ 토요일
  });

  const performersData = twoWeekData.reduce((acc, task) => {
    const person = task.properties.Person.rich_text[0].plain_text;
    const successRate = parseFloat(task.properties['Success Rate'].rich_text[0]?.plain_text) || 0;

    if (!acc[person]) {
      acc[person] = { totalRate: 0, count: 0 };
    }

    acc[person].totalRate += successRate;
    acc[person].count += 1;

    return acc;
  }, {} as Record<string, { totalRate: number; count: number }>);

  const allPerformers = Object.entries(performersData)
    .map(([person, data]) => ({
      person,
      averageRate: data.totalRate / data.count
    }))
    .sort((a, b) => a.averageRate - b.averageRate);

  const allParticipants = Array.from(new Set(twoWeekData.map(task => task.properties.Person.rich_text[0].plain_text))).sort();

  return (
    <div className="admin">
      <h1>Admin Dashboard</h1>
      <button onClick={() => setIsLoggedIn(false)} style={{ position: 'absolute', top: '10px', right: '10px', padding: '5px 10px' }}>
        로그아웃
      </button>
      
      <div className="participants-list">
        <h2>All Participants (Last Two Weeks)</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '20px' }}>
          {allParticipants.map(person => (
            <span key={person} style={{ background: '#f0f0f0', padding: '5px 10px', borderRadius: '5px' }}>
              {person}
            </span>
          ))}
        </div>
      </div>

      <h2>Performance Ranking (Last Two Weeks)</h2>
      <table className="styled-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Person</th>
            <th>Average Success Rate</th>
          </tr>
        </thead>
        <tbody>
          {allPerformers.map(({ person, averageRate }, index) => (
            <tr key={person}>
              <td>{index + 1}</td>
              <td>{person}</td>
              <td>{averageRate.toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Admin;
