import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

interface Task {
  id: string;
  properties: {
    Date: { date: { start: string } };
    Person: { rich_text: [{ plain_text: string }] };
    'Total Items': { number: number };
    'Completed Items': { number: number };
  };
}

const fetchNotionData = async () => {
  const response = await axios.get('/api/notion-data');
  return response.data.results;
};

const EventDashboard = () => {
  const { data, isLoading, error } = useQuery<Task[], Error>('notionData', fetchNotionData);

  if (isLoading) return <div className="p-4 text-center">Loading...</div>;
  if (error) return <div className="p-4 text-center text-red-500">Error: {error.message}</div>;
  if (!Array.isArray(data)) return <div className="p-4 text-center">No data available</div>;

  const startDate = '2024-10-31';
  const endDate = '2024-11-10';

  // 이벤트 기간 내의 데이터만 필터링
  const eventData = data.filter(task => {
    const taskDate = dayjs(task.properties.Date.date.start);
    return taskDate.isAfter(dayjs(startDate).subtract(1, 'day')) && 
           taskDate.isBefore(dayjs(endDate).add(1, 'day'));
  });

  // Person별로 데이터 그룹화 및 합계 계산
  const personStats = eventData.reduce((acc, task) => {
    const person = task.properties.Person.rich_text[0].plain_text;
    if (!acc[person]) {
      acc[person] = {
        totalItems: 0,
        completedItems: 0
      };
    }
    acc[person].totalItems += task.properties['Total Items'].number;
    acc[person].completedItems += task.properties['Completed Items'].number;
    return acc;
  }, {} as Record<string, { totalItems: number; completedItems: number }>);

  return (
    <div className="p-6 max-w-xl mx-auto">
      <div className="bg-white rounded-lg shadow-lg">
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-4 text-center">🎉 이벤트 진행 현황 🎉</h1>
          <div className="text-sm text-gray-500 mb-6 text-center">
            {dayjs(startDate).format('MM/DD')} - {dayjs(endDate).format('MM/DD')}
          </div>
          
          <div className="space-y-4">
            {Object.entries(personStats).map(([person, stats]) => (
              <div 
                key={person} 
                className="flex justify-between items-center p-4 bg-gray-50 rounded-lg"
              >
                <span className="text-lg font-medium">{person}</span>
                <span className={`text-lg font-bold ${stats.totalItems >= 111 ? 'text-red-500' : ''}`}>
                  {stats.completedItems}/{stats.totalItems}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDashboard;
