import React, { useState } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './Dashboard.css';

interface Task {
  id: string;
  properties: {
    Name: { title: [{ plain_text: string }] };
    Date: { date: { start: string } };
    Person: { rich_text: [{ plain_text: string }] };
    Tasks: { rich_text: [{ plain_text: string }] };
    'Success Rate': { rich_text: [{ plain_text: string }] };
    'Total Items': { number: number };
    'Completed Items': { number: number };
  };
}

interface PaginatedResponse {
  results: Task[];
  hasMore: boolean;
  nextCursor: string | null;
  total: number;
}

const fetchNotionData = async (cursor: string | null = null, month: string) => {
  try {
    const response = await axios.get('/api/notion-data', {
      params: {
        month
      }
    });
    return response.data as PaginatedResponse;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

const getMonthlyWeekNumber = (dateStr: string) => {
  const date = dayjs(dateStr);
  const startOfMonth = date.startOf('month');
  
  // 첫째 주는 해당 달의 첫날부터 첫 번째 토요일까지로 설정
  const firstSaturday = startOfMonth.day() === 6 ? startOfMonth : startOfMonth.add(6 - startOfMonth.day(), 'day');
  
  // 첫째 주: 첫날부터 첫 토요일까지
  if (date.isSame(startOfMonth, 'day') || date.isBefore(firstSaturday, 'day')) {
    return `${date.format('M')}월 1W`;
  }

  // 둘째 주 이후: 일요일 시작으로 주차 계산
  const firstSundayAfterFirstSaturday = firstSaturday.add(1, 'day');
  const weekNumber = Math.floor(date.diff(firstSundayAfterFirstSaturday, 'day') / 7) + 2;

  return `${date.format('M')}월 ${weekNumber}W`;
};

const calculateAverageSuccessRate = (tasks: Task[]) => {
  if (!tasks.length) return "0.00";
  const totalSuccessRate = tasks.reduce((sum, task) => {
    const rate = parseFloat(task.properties['Success Rate'].rich_text[0]?.plain_text) || 0;
    return sum + rate;
  }, 0);
  return (totalSuccessRate / tasks.length).toFixed(2);
};

const Dashboard: React.FC = () => {
  const [selectedPerson, setSelectedPerson] = useState<string>('');
  const [currentMonth, setCurrentMonth] = useState(dayjs().startOf('month'));

  const { 
    data, 
    isLoading, 
    error, 
    isFetching,
  } = useQuery({
    queryKey: ['notionData', currentMonth.format('YYYY-MM')],
    queryFn: () => fetchNotionData(null, currentMonth.format('YYYY-MM')),
    staleTime: 5 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
  });

  if (isLoading) return <div className="loading-state">Loading...</div>;
  if (error) return <div className="error-state">Error: {(error as Error).message}</div>;
  if (!data?.results) return <div className="no-data-state">No data available</div>;

  const handleMonthChange = (offset: number) => {
    const newMonth = currentMonth.add(offset, 'month');
    setCurrentMonth(newMonth);
    window.scrollTo(0, 0);
  };

  const filteredData = data.results.filter(task => {
    return !selectedPerson || task.properties.Person.rich_text[0].plain_text === selectedPerson;
  });

  const uniquePersons = Array.from(
    new Set(data.results.map(task => task.properties.Person.rich_text[0].plain_text))
  );

  const groupedData = filteredData.reduce((weeks, task) => {
    const weekLabel = getMonthlyWeekNumber(task.properties.Date.date.start);
    if (!weeks[weekLabel]) weeks[weekLabel] = [];
    weeks[weekLabel].push(task);
    return weeks;
  }, {} as Record<string, Task[]>);

  const weekData = Object.entries(groupedData).map(([week, tasks]) => {
    const dailySuccessRates = tasks.reduce((days, task) => {
      const taskDate = dayjs(task.properties.Date.date.start).format('YYYY-MM-DD');
      const successRate = parseFloat(task.properties['Success Rate'].rich_text[0]?.plain_text) || 0;

      if (!days[taskDate]) {
        days[taskDate] = [];
      }
      days[taskDate].push(successRate);

      return days;
    }, {} as Record<string, number[]>);

    const dailyAverages = Object.entries(dailySuccessRates).map(([date, rates]) => ({
      date,
      average: rates.reduce((sum, rate) => sum + rate, 0) / rates.length,
    }));

    return { week, dailyAverages, tasks };
  });

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <h1>&#127775; Task Dashboard &#127775;</h1>
        
        <div className="controls">
          <button 
            onClick={() => handleMonthChange(-1)}
            className="month-control-button"
            disabled={isFetching}
          >
            ←
          </button>
          <span className="current-month">{currentMonth.format('MMMM YYYY')}</span>
          <button 
            onClick={() => handleMonthChange(1)}
            className="month-control-button"
            disabled={isFetching}
          >
            →
          </button>
        </div>

        <div className="monthly-summary">
          <h2 className="monthly-average">
            Monthly Average Success Rate: {calculateAverageSuccessRate(filteredData)}%
          </h2>
          <div className="monthly-stats">
            <span>Total Tasks: {filteredData.length}</span>
            <span>Total Success: {
              filteredData.reduce((sum, task) => sum + (task.properties['Completed Items'].number || 0), 0)
            }</span>
            <span>Total Items: {
              filteredData.reduce((sum, task) => sum + (task.properties['Total Items'].number || 0), 0)
            }</span>
          </div>
        </div>

        <div className="filters">
          <label className="person-filter">
            Select Person:
            <select 
              value={selectedPerson} 
              onChange={(e) => setSelectedPerson(e.target.value)}
              className="person-select"
              disabled={isFetching}
            >
              <option value="">All</option>
              {uniquePersons.map(person => (
                <option key={person} value={person}>{person}</option>
              ))}
            </select>
          </label>
        </div>
      </div>

      <div className="dashboard-content">
        {weekData.map(({ week, dailyAverages, tasks }) => (
          <div key={week} className="weekly-section">
            <h2 className="week-header">
              {week} - 평균 달성률: {calculateAverageSuccessRate(tasks)}%
            </h2>

            <div className="chart-container">
              <Line
                data={{
                  labels: dailyAverages.map(d => d.date).reverse(),
                  datasets: [
                    {
                      label: 'Daily Success Rate',
                      data: dailyAverages.map(d => d.average).reverse(),
                      fill: false,
                      borderColor: 'rgba(75,192,192,1)',
                      tension: 0.1
                    }
                  ]
                }}
                options={{
                  responsive: true,
                  scales: {
                    y: {
                      beginAtZero: true,
                      max: 100,
                      title: {
                        display: true,
                        text: 'Success Rate (%)'
                      }
                    },
                    x: {
                      title: {
                        display: true,
                        text: 'Date'
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    tooltip: {
                      mode: 'index',
                      intersect: false,
                    }
                  }
                }}
              />
            </div>

            <div className="table-container">
              <table className="styled-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Person</th>
                    <th>Tasks</th>
                    <th>Success Rate</th>
                    <th>Total Items</th>
                    <th>Completed Items</th>
                  </tr>
                </thead>
                <tbody>
                  {tasks.map((task: Task) => (
                    <tr key={task.id}>
                      <td data-label="Date">{task.properties.Date.date.start}</td>
                      <td data-label="Person">
                        {task.properties.Person.rich_text[0].plain_text}
                      </td>
                      <td data-label="Tasks">
                        {task.properties.Tasks.rich_text[0].plain_text}
                      </td>
                      <td data-label="Success Rate">
                        {task.properties['Success Rate'].rich_text[0].plain_text}
                      </td>
                      <td data-label="Total Items">
                        {task.properties['Total Items'].number}
                      </td>
                      <td data-label="Completed Items">
                        {task.properties['Completed Items'].number}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
